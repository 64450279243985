.editing-mode {
	border-style: dotted;
}

.edit-button {
	position: fixed;
	background-color: #1016c9;
	color: #fff;
	border: none;
	padding: 10px 20px;
	font-size: 1em;
	margin-right: 10px;
	cursor: pointer;
	bottom: 10px;
	left: 10px;
	transition: background-color 0.3s ease;
}
