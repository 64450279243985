.footer {
	background-color: #f9f9f9; /* Light gray background */
	color: #333; /* Dark text color for readability */
	padding: 10px; /* Adjusted padding for better spacing */
	border-top: 1px solid #ddd; /* Light border for separation */
}

.footer-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer-column {
	flex: 1;
	margin: 5px;
}

.footer-links,
.footer-social-columns {
	display: flex;
	flex-wrap: wrap;
}

.footer-links-column,
.footer-social-column {
	flex: 1;
	margin: 5px;
}

.footer-links ul,
.footer-social ul,
.footer-legal ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.footer-links a,
.footer-social a,
.footer-legal a {
	color: #555; /* Slightly lighter dark color for links */
	text-decoration: none;
	display: block;
	margin: 2px 0; /* Reduced margin for tighter spacing */
	padding: 2px; /* Reduced padding */
	position: relative;
	transition:
		color 0.3s,
		transform 0.3s; /* Smooth transition */
}

.footer-links a:hover,
.footer-social a:hover,
.footer-legal a:hover {
	font-weight: bold; /* Bold text on hover */
	color: #1016c9; /* Hover color */
	text-decoration: underline; /* Underline on hover */
	transform: translateX(3px); /* Less shift on hover */
	animation: shake 0.5s; /* Optional shake animation */
}

.footer-links a::after,
.footer-social a::after,
.footer-legal a::after {
	content: '→'; /* Arrow or string CTA */
	position: absolute;
	right: 0;
	opacity: 0;
	transition: opacity 0.3s;
}

.footer-links a:hover::after,
.footer-social a:hover::after,
.footer-legal a:hover::after {
	opacity: 1;
}

.footer-social-columns {
	display: flex;
	flex-wrap: wrap;
}

.footer-social-column {
	flex: 1;
	margin: 5px; /* Reduced margin for tighter spacing */
}

.footer-social-column ul {
	display: flex;
	flex-direction: column;
	gap: 3px; /* Reduced gap between items */
}

.footer-social-column li {
	margin: 0;
}

.footer-legal ul {
	margin: 0;
}

.footer-bottom {
	text-align: right;
	font-size: 12px;
	margin-top: 10px; /* Reduced top margin */
}

.footer-bottom p {
	margin: 0;
	color: #777; /* Light gray color for the footer text */
}
