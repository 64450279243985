.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background-color: #333;
	color: white;
}

.logo a {
	color: white;
	text-decoration: none;
	font-size: 24px;
}

.navigation ul {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
}

.navigation li {
	margin: 0 15px;
}

.navigation a {
	color: white;
	text-decoration: none;
}

.cta-button a {
	color: #ffcc00;
	text-decoration: none;
	background-color: #222;
	padding: 10px 20px;
	border-radius: 5px;
}
