.blog-loader {
	display: flex;
}

.left-panel {
	width: 30%;
	overflow-y: auto;
	border-right: 1px solid #ddd;
}

.right-panel {
	width: 70%;
	padding: 20px;
	overflow-y: auto;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	cursor: pointer;
	padding: 10px;
	border-bottom: 1px solid #ddd;
}

.selected {
	background-color: #f0f0f0;
}

.accordion-item {
	border-bottom: 1px solid #ddd;
}

.accordion-header {
	cursor: pointer;
	padding: 10px;
}

.accordion-body {
	padding: 10px;
}
