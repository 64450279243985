.interactive-demo-page {
	padding: 20px;
}

.interactive-demo-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.demo-step {
	margin-bottom: 20px;
}

.demo-step h2 {
	font-size: 24px;
}

.demo-step p {
	font-size: 18px;
	line-height: 1.6;
}
