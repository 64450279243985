/* PricingPage.css */
.pricing-page {
	padding: 20px;
	font-family: Arial, sans-serif;
}

.pricing-header {
	text-align: center;
	padding: 20px 0;
	background-color: #282c34;
	color: white;
}

.pricing-plans {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
}

.pricing-plan {
	flex: 1 1 calc(33% - 40px); /* Adjust width as needed */
	max-width: 300px;
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.pricing-plan h2 {
	font-size: 1.75rem;
	margin-bottom: 10px;
}

.pricing-plan .price {
	font-size: 2rem;
	margin: 10px 0;
	color: #61dafb;
}

.pricing-plan ul {
	list-style: none;
	padding: 0;
	margin: 20px 0;
}

.pricing-plan ul li {
	font-size: 1rem;
	color: #333;
	margin: 10px 0;
}

.pricing-link {
	display: inline-block;
	color: #61dafb;
	text-decoration: none;
	font-weight: bold;
	margin-top: 10px;
}

.pricing-link:hover {
	text-decoration: underline;
}
