.hero-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 20px;
	background-color: #f4f4f4;
	/* Light background color */
	color: #333;
	min-height: 400px;
	position: relative;
}

.hero-content {
	max-width: 50%;
}

.hero-content h1 {
	font-size: 2.5em;
	margin-bottom: 20px;
}

.hero-content p {
	font-size: 1.2em;
	margin-bottom: 30px;
	line-height: 1.6;
}

.hero-buttons {
	margin-top: 20px;
}

.hero-section .cta-button {
	background-color: #1016c9;
	/* Primary button color */
	color: #fff;
	border: none;
	padding: 10px 20px;
	font-size: 1em;
	margin-right: 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.cta-button:hover {
	background-color: #0a0a99;
	/* Darker shade on hover */
}

.hero-image img {
	max-width: 100%;
	height: auto;
	display: block;
}
