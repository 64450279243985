.docs-page {
	padding: 20px;
}

.docs-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.docs-page h2 {
	font-size: 24px;
	margin-top: 20px;
}

.docs-page p {
	font-size: 18px;
	line-height: 1.6;
}
