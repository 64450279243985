/* CareerPage.css */

.career-page {
	padding: 2rem;
	font-family: Arial, sans-serif;
	background-color: #f9f9f9;
}

.light {
	color: #fff;
	background-color: #007bff;
}

.dark {
	color: #252525;
	background-color: #7d3434;
}

.career-header {
	text-align: center;
	margin-bottom: 2rem;
}

.career-header h1 {
	font-size: 2.5rem;
	color: #333;
}

.career-header p {
	font-size: 1.25rem;
	color: #666;
}

.career-listings {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.career-listing {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 1.5rem;
	margin-bottom: 2rem;
	width: 80%;
	max-width: 800px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.career-listing h2 {
	font-size: 2rem;
	color: #333;
	margin-bottom: 0.5rem;
}

.career-listing p {
	font-size: 1rem;
	color: #555;
	margin: 0.5rem 0;
}

.career-listing h3 {
	font-size: 1.25rem;
	color: #333;
	margin-top: 1rem;
}

.career-listing ul {
	list-style: disc;
	padding-left: 1.5rem;
}

.career-listing ul li {
	font-size: 1rem;
	color: #555;
	margin: 0.5rem 0;
}

.career-listing a {
	color: #007bff;
	text-decoration: none;
}

.career-listing a:hover {
	text-decoration: underline;
}