/* FeaturesPage.css */
.features-page {
	padding: 20px;
	font-family: Arial, sans-serif;
}

.features-header {
	text-align: center;
	padding: 20px 0;
	background-color: #282c34;
	color: white;
}

.features-list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
}

.feature-item {
	flex: 1 1 calc(50% - 40px); /* Adjust width as needed */
	max-width: 300px;
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-image {
	width: 100px;
	height: auto;
	margin-bottom: 15px;
}

.feature-item h2 {
	font-size: 1.5rem;
	margin-bottom: 10px;
}

.feature-item p {
	font-size: 1rem;
	color: #333;
	margin-bottom: 15px;
}

.feature-link {
	display: inline-block;
	color: #61dafb;
	text-decoration: none;
	font-weight: bold;
}

.feature-link:hover {
	text-decoration: underline;
}
