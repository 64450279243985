.start-free-trial {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
}

.start-free-trial h1 {
	margin-bottom: 20px;
	font-size: 2em;
}

.start-free-trial p {
	margin-bottom: 20px;
	font-size: 1.2em;
}

.start-free-trial form {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.start-free-trial label {
	display: flex;
	flex-direction: column;
}

.start-free-trial input {
	padding: 10px;
	font-size: 1em;
}

.start-free-trial button {
	padding: 15px;
	background-color: #ffcc00;
	border: none;
	color: white;
	font-size: 1em;
	cursor: pointer;
}

.start-free-trial button:hover {
	background-color: #e6b800;
}
