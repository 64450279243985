/* ContactUsPage CSS */
.contact-page {
	padding: 20px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 600px;
	margin: 0 auto;
}

.contact-page h2 {
	color: #007bff;
	margin-bottom: 20px;
	text-align: center;
}

.contact-page .form-group {
	margin-bottom: 20px;
}

.contact-page label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.contact-page input,
.contact-page textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	font-size: 16px;
}

.contact-page textarea {
	resize: vertical;
	min-height: 100px;
}

.contact-page button {
	background-color: #007bff;
	color: #ffffff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	display: block;
	width: 100%;
	margin-top: 10px;
}

.contact-page button:hover {
	background-color: #0056b3;
}

.contact-page .status-message {
	margin-top: 15px;
	font-size: 16px;
	color: #007bff;
	text-align: center;
}
