.demo {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
}

.demo h1 {
	margin-bottom: 20px;
	font-size: 2.5em;
}

.demo p {
	margin-bottom: 20px;
	font-size: 1.2em;
}

.demo-content {
	margin-top: 20px;
}

.demo-button {
	padding: 15px 30px;
	background-color: #007bff;
	border: none;
	color: white;
	font-size: 1em;
	cursor: pointer;
	border-radius: 5px;
}

.demo-button:hover {
	background-color: #0056b3;
}
