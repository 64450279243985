body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	background-color: #282c34;
	color: white;
}

.header .logo a {
	color: white;
	text-decoration: none;
	font-size: 1.5rem;
}

.header nav ul {
	list-style: none;
	display: flex;
	gap: 20px;
}

.header nav ul li a {
	color: white;
	text-decoration: none;
}

.header .cta-button a {
	color: white;
	background-color: #61dafb;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
}

.hero-section {
	text-align: center;
	padding: 100px 20px;
	background-color: #f0f0f0;
}

.hero-section h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.hero-section p {
	font-size: 1.25rem;
	margin-bottom: 40px;
}

.hero-buttons button {
	padding: 10px 20px;
	margin: 0 10px;
	font-size: 1rem;
}

.hero-image img {
	max-width: 100%;
	height: auto;
}

.features-section,
.how-it-works,
.testimonials,
.call-to-action {
	padding: 60px 20px;
	text-align: center;
}

.features-list,
.steps-list,
.testimonials-list {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 20px;
}

.feature-item,
.step-item,
.testimonial-item {
	max-width: 300px;
	text-align: center;
}

.testimonial-item img {
	max-width: 100px;
	margin-bottom: 10px;
}

.cta-buttons button {
	padding: 10px 20px;
	margin: 10px;
	font-size: 1rem;
}
