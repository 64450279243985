.community-page {
	padding: 20px;
}

.community-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.community-link {
	margin-bottom: 20px;
}

.community-link h2 {
	font-size: 24px;
}

.community-link p {
	font-size: 18px;
	line-height: 1.6;
}

.community-link a {
	font-size: 16px;
	color: #007bff;
	text-decoration: none;
}
