/* SolutionsPage.css */
.solutions-page {
	padding: 20px;
	font-family: Arial, sans-serif;
}

.solutions-header {
	text-align: center;
	padding: 20px 0;
	background-color: #282c34;
	color: white;
}

.use-cases {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
}

.use-case {
	flex: 1 1 calc(50% - 40px); /* Adjust width as needed */
	max-width: 300px;
	text-align: center;
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.use-case-image {
	width: 100px;
	height: auto;
	margin-bottom: 15px;
}

.use-case h2 {
	font-size: 1.5rem;
	margin: 10px 0;
}

.use-case p {
	font-size: 1rem;
	color: #333;
}
