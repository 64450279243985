/* PrivacyPolicyPage.css */

.privacy-policy-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 2rem;
	font-family: Arial, sans-serif;
	line-height: 1.6;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
	color: #333;
}

.privacy-policy-container h1 {
	font-size: 2.5rem;
	margin-bottom: 1rem;
}

.privacy-policy-container h2 {
	font-size: 1.75rem;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.privacy-policy-container p {
	margin-bottom: 1rem;
	color: #666;
}

.privacy-policy-container section {
	margin-bottom: 2rem;
}

.privacy-policy-container a {
	color: #007bff;
	text-decoration: none;
}

.privacy-policy-container a:hover {
	text-decoration: underline;
}
