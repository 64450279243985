/* AboutUsPage.css */
.about-us-page {
	padding: 20px;
	font-family: Arial, sans-serif;
}

.about-header {
	text-align: center;
	padding: 20px 0;
	background-color: #282c34;
	color: white;
}

.mission-vision,
.team,
.careers {
	margin: 40px 0;
}

.mission-vision h2,
.team h2,
.careers h2 {
	color: #282c34;
}

.mission-vision p,
.team p,
.careers p {
	margin: 10px 0;
	line-height: 1.6;
}

.team-members {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.team-member {
	max-width: 300px;
	text-align: center;
	margin: 20px;
}

.team-member img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.careers ul {
	list-style: none;
	padding: 0;
}

.careers ul li {
	margin: 10px 0;
}
