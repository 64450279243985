.video-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.video-modal-content {
	position: relative;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	max-width: 90%;
	max-height: 80%;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 24px;
	color: #333;
	cursor: pointer;
}

.video-player {
	width: 640px;
	height: 360px;
	margin-left: 25%;
}

/* Disable download and speed options */
/* .video-player::-webkit-media-controls-panel {
  display: none !important;
} */

.video-summary {
	margin-top: 20px;
	max-height: 300px;
	overflow-y: scroll;
}
