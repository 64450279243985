.events-webinars-page {
	padding: 20px;
}

.events-webinars-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.event {
	margin-bottom: 20px;
}

.event h2 {
	font-size: 24px;
}

.event p {
	font-size: 18px;
	line-height: 1.6;
}

.event a {
	font-size: 16px;
	color: #007bff;
	text-decoration: none;
}
