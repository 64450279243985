.each-blog {
	border: 1px solid #ddd;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	background-color: #f9f9f9;
}

.each-blog h3 {
	margin: 0;
}

.each-blog button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}

.each-blog button:hover {
	background-color: #0056b3;
}
