.each-blog {
	padding: 20px;
}

.date-published {
	font-size: 1.1em;
	color: #666;
	margin: 5px 0;
}

.tags {
	margin: 10px 0;
}

.tag {
	display: inline-block;
	background-color: #eee;
	border-radius: 5px;
	padding: 5px 10px;
	margin: 0 5px 5px 0;
	font-size: 0.9em;
	color: #333;
}

.about-author {
	margin-top: 20px;
}
