/* CourseComponent.css */

/* General styles for the section */
.course-section {
	padding: 20px;
	background-color: #f4f4f4;
	text-align: center;
}

.course-section h2 {
	font-size: 2rem;
	color: #222;
	margin-bottom: 20px;
}

/* Carousel container styles */
.course-carousel {
	display: flex;
	overflow-x: auto;
	gap: 15px;
	padding: 10px;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	/* for smooth scrolling on iOS */
}

.course-carousel::-webkit-scrollbar {
	display: none;
	/* Hide scrollbar for a cleaner look */
}

/* Course card styles */
.course-card {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	flex: 0 0 auto;
	width: 250px;
	text-align: center;
	transition:
		transform 0.3s ease,
		box-shadow 0.3s ease;
	overflow: hidden;
	cursor: pointer;
	/* Show pointer cursor on hover */
}

.course-card img {
	width: 100%;
	height: 150px;
	object-fit: cover;
}

.course-info {
	padding: 15px;
}

.course-card h3 {
	font-size: 1.2rem;
	color: #333;
	margin: 10px 0;
}

.course-card p {
	color: #555;
	font-size: 0.9rem;
}

/* Hover effect for course cards */
.course-card:hover {
	transform: scale(1.05);
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}
